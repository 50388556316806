import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Layout from '../../components/layout';
import { useEffect } from 'react';
import { logAnalysis } from '../../../api/analytic';

const BlogPost = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: { title, embeddedImagesLocal, date, type, excerpt },
      body,
      id,
      slug,
    },
  },
}) => {
  useEffect(() => {
    logAnalysis(slug);
  }, []);

  return (
    <Layout>
      <article className='prose prose-orange xl:min-w-[900px] m-auto p-4 xl:p-0'>
        <Link
          to={'/'}
          className={
            'flex place-items-center gap-1 text-lg underline italic font-serif font-bold'
          }
        >
          HOME
        </Link>
        <div className='flex justify-between'>
          <p className='text-lg font-medium italic'>{date}</p>
          <p className='italic text-lg font-bold'>#{type}</p>
        </div>
        <div className='text-4xl sm:text-6xl font-bold font-sans mb-10'>
          {title.toUpperCase()}
        </div>
        <MDXProvider>
          <MDXRenderer
            remoteImages={embeddedImagesRemote}
            localImages={embeddedImagesLocal}
          >
            {body}
          </MDXRenderer>
        </MDXProvider>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        type
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        excerpt
      }
      body
      id
      slug
    }
  }
`;
export default BlogPost;
