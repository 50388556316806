import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
const Layout = ({ pageTitle = 'Home', children }) => {
  const data = useStaticQuery(graphql`
    query GetSiteMetadata {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div>
      <title>
        {data.site.siteMetadata.title} | {pageTitle}
      </title>

      <main>
        <section className='sm:px-[10%] 2xl:px-[25%] sm:mt-16'>
          {children}
        </section>
      </main>

      <Footer />
    </div>
  );
};

const Footer = () => {
  return (
    <a
      className='fixed bottom-3 right-3 underline underline-offset-1'
      href='mailto:alex.tran1502@gmail.com'
      target='_newtab'
    >
      alex.tran1502@gmail.com
    </a>
  );
};
export default Layout;
